const OFFER_MODAL_ID = "scout_template_modal";

const noneFunction = (e, data) => {};

export function openScoutTemplateModal(callback = noneFunction) {
    const $modal = $(`.js-modal-basic[data-modalid=${OFFER_MODAL_ID}]`);
    const $close = $(`.js-modal-basic-close[data-modalid=${OFFER_MODAL_ID}]`);
    const $content = $modal.find(
        `.js-modal-basic-content[data-modalid=${OFFER_MODAL_ID}]`
    );
    const $overlay = $modal.find(
        `.js-modal-basic-overlay[data-modalid=${OFFER_MODAL_ID}]`
    );

    $overlay.removeClass("is-hide");
    $content.removeClass("is-hide");

    $close.one("click", function (e) {
        $overlay.addClass("is-hide");
        $content.addClass("is-hide");
    });

    $overlay.one("click", function (e) {
        $overlay.addClass("is-hide");
        $content.addClass("is-hide");
    });

    $modal.on("scout_template_form_v2:submit", function (e, data) {
        callback(e, data);
    });
}

const UNEDIT_OFFER_MODAL_ID = "unedit_scout_template_modal";
export const UneditScoutTemplateSubmitTrigger =
    "unedit_scout_template_form:submit";
export function openUneditScoutTemplateModal(callback = noneFunction) {
    const $modal = $(`.js-modal-basic[data-modalid=${UNEDIT_OFFER_MODAL_ID}]`);
    const $close = $(
        `.js-modal-basic-close[data-modalid=${UNEDIT_OFFER_MODAL_ID}]`
    );
    const $content = $modal.find(
        `.js-modal-basic-content[data-modalid=${UNEDIT_OFFER_MODAL_ID}]`
    );
    const $overlay = $modal.find(
        `.js-modal-basic-overlay[data-modalid=${UNEDIT_OFFER_MODAL_ID}]`
    );

    $overlay.removeClass("is-hide");
    $content.removeClass("is-hide");

    $close.one("click", function (e) {
        $overlay.addClass("is-hide");
        $content.addClass("is-hide");
    });

    $overlay.one("click", function (e) {
        $overlay.addClass("is-hide");
        $content.addClass("is-hide");
    });

    $modal.on(UneditScoutTemplateSubmitTrigger, function (e, data) {
        callback(e, data);
    });
}
