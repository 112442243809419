import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["container", "offer", "selection", "submit"];

    static values = {
        brandid: String,
        storeid: String,
    };

    connect() {
        // const company_id = this.companyidValue
        this.offerTargets.forEach(offer => {
            const brand_id = offer.dataset.brandId;
            const store_id = offer.dataset.storeId;
            // console.log(brand_id, store_id)
            if (!this.brandidValue && !this.storeidValue) {
                offer.style.display = "block";
                return;
            }
            if (this.brandidValue && !this.storeidValue) {
                if (this.brandidValue == brand_id) {
                    offer.style.display = "block";
                } else {
                    offer.style.display = "none";
                }
                return;
            }
            if (!this.brandidValue && this.storeidValue) {
                if (this.storeidValue == store_id) {
                    offer.style.display = "block";
                } else {
                    offer.style.display = "none";
                }
                return;
            }
            if (this.brandidValue && this.storeidValue) {
                if (
                    this.brandidValue == brand_id &&
                    this.storeidValue == store_id
                ) {
                    offer.style.display = "block";
                } else {
                    offer.style.display = "none";
                }
                return;
            }
            offer.style.display = "none";
        });
    }

    handleOnSelect() {
        const selections = this.selectionTargets;
        const has_selection = selections.some(selection => {
            return selection.checked;
        });
        if (has_selection) {
            this.submitTarget.disabled = false;
            this.submitTarget.classList.remove("is-disabled");
        } else {
            this.submitTarget.disabled = true;
            this.submitTarget.classList.add("is-disabled");
        }
    }

    handleOnSubmit() {
        const selected_offer = this.selectionTargets.find(selection => {
            return selection.checked;
        });

        $(this.element).trigger("scout_offer_select_modal_v2:submit", {
            selected_offer,
        });
    }

    brandidValueChanged() {
        this.connect();
    }

    storeidValueChanged() {
        this.connect();
    }
}
