import { Controller } from "@hotwired/stimulus";
import { getCsrfToken } from "./lib/utils";
import { UneditScoutTemplateSubmitTrigger } from "../components/ScoutForm/lib/scoutTemplateModalProxy.ts";

function fetchStores(brandId) {
    const ENDPOINT_URL = `/api/v1/stores/search.json?brand_id=${brandId}&offered=true`;
    return fetch(ENDPOINT_URL)
        .then(res => res.json())
        .then(json => {
            if (json.status == "success") {
                return json.data;
            }
        })
        .catch(error => console.log(error));
}

export default class extends Controller {
    static targets = [
        "templateid",
        "templateTitle",
        "sender",
        "title",
        "content",
        "submit",
    ];

    static values = {
        formId: String,
        templateid: String,
        templateTitle: String,
        companyid: String,
        sender: String,
        title: String,
        content: String,
    };

    connect() {
        this._setValueAllTargets();
        this._validate();
    }

    // titleに入力された時
    templateTitleValueHandler(event) {
        this.templateTitleValue = event.target.value;
        this._validate();
    }

    // senderが変更された時
    senderValueHandler(event) {
        this.senderValue = event.target.value;
        this._validate();
    }

    // titleが変更された時
    titleValueHandler(event) {
        this.titleValue = event.target.value;
        this._validate();
    }

    // contentが変更された時
    contentValueHandler(event) {
        this.contentValue = event.target.value;
        this._validate();
    }

    // 適用ボタンが押された時
    handleOnSubmit(e) {
        e.preventDefault();

        if (this._isValid()) {
            const scoutTemplateModal = document.querySelector(
                `div[data-controller="unedit-scout-template-modal"]`
            );
            this.application
                .getControllerForElementAndIdentifier(
                    scoutTemplateModal,
                    "unedit-scout-template-modal"
                )
                .fetchTemplate();
            $(this.element).trigger(UneditScoutTemplateSubmitTrigger, {
                template: {
                    sender: this.senderValue,
                    title: this.titleValue,
                    content: this.contentValue,
                },
            });

            const $close = $(
                `.js-modal-basic-close[data-modalid="unedit_scout_template_modal"]`
            );
            this.initializeTemplateValues();
            $close.trigger("click");
        }
    }

    // テンプレートフォームの値を初期化する
    initializeTemplateValues() {
        this.setTemplateValues("", "", "", "", "");
    }

    // テンプレートフォームの値を設定する
    setTemplateValues(templateId, templateTitle, sender, title, content) {
        this.templateidValue = templateId;
        this.templateTitleValue = templateTitle;
        this.senderValue = sender;
        this.titleValue = title;
        this.contentValue = content;
        this._setValueAllTargets();
        this._validate();
    }

    // フォーム内のinputのvalueを設定する
    _setValueAllTargets() {
        this.templateTitleTarget.value = this.templateTitleValue;
        this.senderTarget.value = this.senderValue;
        this.titleTarget.value = this.titleValue;
        this.contentTarget.value = this.contentValue;
    }

    // フォームのバリデーション
    _validate() {
        if (this._isValid()) {
            this.submitTarget.disabled = false;
            this.submitTarget.classList.remove("is-disabled");
        } else {
            this.submitTarget.disabled = true;
            this.submitTarget.classList.add("is-disabled");
        }
    }

    // フォームのバリデーション
    _isValid() {
        return !!(
            this.templateTitleValue &&
            this.titleValue &&
            this.contentValue
        );
    }
}
