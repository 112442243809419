import { Controller } from "@hotwired/stimulus";
import { getCsrfToken } from "./lib/utils";

export default class extends Controller {
    static targets = ["templates", "template"];

    static values = {
        id: String,
        templates: Array,
    };

    // 最初の一回だけ呼ばれる
    connect() {
        this.fetchTemplate();
        // Listen for the custom event
        $(document).on(
            "changeTemplateId",
            this.handleOnChangeTemplateId.bind(this)
        );
    }

    handleOnChangeTemplateId(event, id) {
        // idが存在する場合
        if (id) {
            const template = this.templatesValue.find(
                template => String(template.id) === String(id)
            );
            console.log(template);
            this._toScoutTemplateForm(template);
        } else {
            this._templateFormNew();
        }
    }

    // 「新規作成」が押された時
    handleOnTemplateNew() {
        this._templateFormNew();
    }

    // onChangeTemplatesValue
    onChangeTemplatesValue() {
        this._renderTemplateList(this.templatesValue);
    }

    // scout-template-form-v2 からも呼ばれる
    fetchTemplate() {
        const ENDPOINT_URL = `/manage/scouts/templates.json`;
        const requestBody = {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getCsrfToken(),
            },
        };
        return fetch(ENDPOINT_URL, requestBody)
            .then(res => res.json())
            .then(json => {
                this.templatesValue = json;
            });
    }

    // テンプレートフォームに値をセット
    _toScoutTemplateForm(template) {
        let brandId = template.scout_template_brand
            ? template.scout_template_brand.brand_id
            : null;
        template.store ? (brandId = template.store.brand_id) : null;
        const storeId = template.scout_template_store
            ? template.scout_template_store.store_id
            : null;
        const $templateForm = this._templateForm();

        // テンプレートフォームに値をセット
        this.application
            .getControllerForElementAndIdentifier(
                $templateForm,
                "scout-template-form-v3"
            )
            .setTemplateValues(
                template.id,
                template.template_type,
                template.template_title,
                brandId,
                storeId,
                template.sender,
                template.title,
                template.content
            );
    }

    // テンプレートフォームのDOMを取得
    _templateForm() {
        return document.getElementById(`scout_template_form`);
    }

    // テンプレートフォームを初期化
    _templateFormNew() {
        this.application
            .getControllerForElementAndIdentifier(
                this._templateForm(),
                "scout-template-form-v3"
            )
            .initializeTemplateValues();
    }
}
