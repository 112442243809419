import { Controller } from "@hotwired/stimulus";
import { getCsrfToken } from "./lib/utils";

function fetchStores(brandId) {
    const ENDPOINT_URL = `/api/v1/stores/search.json?brand_id=${brandId}&offered=true`;
    return fetch(ENDPOINT_URL)
        .then(res => res.json())
        .then(json => {
            if (json.status == "success") {
                return json.data;
            }
        })
        .catch(error => console.log(error));
}

export default class extends Controller {
    static targets = [
        "templateid",
        "templateTitle",
        "scouttype",
        "brandid",
        "storebrandid",
        "storeid",
        "storeList",
        "sender",
        "title",
        "content",
        "submit",
    ];

    static values = {
        formId: String,
        templateid: String,
        templateTitle: String,
        scouttype: String,
        companyid: String,
        brandid: String,
        storebrandid: String,
        storeid: String,
        sender: String,
        title: String,
        content: String,
    };

    connect() {
        this._setValueAllTargets();
        this._validate();
    }

    // titleに入力された時
    templateTitleValueHandler(event) {
        this.templateTitleValue = event.target.value;
        this._validate();
    }

    // scouttypeがform上で選択された時
    scouttypeValueHandler(event) {
        let selectValue = event.target.value;
        this.scouttypeValue = selectValue;
        this._displayOnScouttype(selectValue);
        this._validate();
    }

    // scouttypeがvalueによって変更された時
    scouttypeValueChanged(value) {
        this._displayOnScouttype(value);
    }

    // scouttypeがbrand時にbrandidが変更された時
    brandidValueForBrandHandler(event) {
        let selectValue = event.target.value;

        if (this.scouttypeValue === "brand") {
            this.brandidValue = selectValue;
        }
        this._validate();
    }

    // scouttypeがstore時にbrandidが変更された時
    brandidValueForStoreHandler(event) {
        let selectValue = event.target.value;
        if (this.scouttypeValue === "store") {
            if (this.storebrandidValue !== selectValue) {
                this._renderBrandStoreSelectOptions(
                    selectValue,
                    this.storeidValue
                );
                this.storebrandidValue = selectValue;
            }
        }
        this._validate();
    }

    // scouttypeがstore時にstoreidが変更された時
    storeidValueForStoreHandler(event) {
        let selectValue = event.target.value;

        if (this.scouttypeValue === "store") {
            this.storeidValue = selectValue;
            this.storeidTarget.value = selectValue;
        }
        this._validate();
    }

    // senderが変更された時
    senderValueHandler(event) {
        this.senderValue = event.target.value;
        this._validate();
    }

    // titleが変更された時
    titleValueHandler(event) {
        this.titleValue = event.target.value;
        this._validate();
    }

    // contentが変更された時
    contentValueHandler(event) {
        this.contentValue = event.target.value;
        this._validate();
    }

    // 保存ボタンが押された時
    handleOnSubmit(e) {
        e.preventDefault();

        if (this._isValid()) {
            const $close = $(
                `.js-modal-basic-close[data-modalid="scout_template_modal"]`
            );
            this._saveTemplate().then(json => {
                $(this.element).trigger("scout_template_form_v3:submit", {
                    template: json,
                });
                location.reload(); // ページをリロードする
            });
            this.initializeTemplateValues();
            $close.trigger("click");
        }
    }

    // テンプレートフォームの値を初期化する
    initializeTemplateValues() {
        this.setTemplateValues("", "store", "", "", "", "", "", "");
        this._renderBrandStoreSelectOptions();
    }

    // テンプレートフォームの値を設定する
    setTemplateValues(
        templateId,
        scouttype,
        templateTitle,
        brandid,
        storeid,
        sender,
        title,
        content
    ) {
        this.templateidValue = templateId;
        this.scouttypeValue = scouttype;
        this.templateTitleValue = templateTitle;
        if (this.scouttypeValue === "company") {
            this.brandidValue = "";
            this.storebrandidValue = "";
            this.storeidValue = "";
        } else if (this.scouttypeValue === "brand" && brandid) {
            this.brandidValue = brandid;
            this.storebrandidValue = "";
            this.storeidValue = "";
        } else if (this.scouttypeValue === "store" && storeid) {
            this.brandidValue = brandid;
            this.storebrandidValue = brandid;
            this.storeidValue = storeid;
        }
        this.senderValue = sender;
        this.titleValue = title;
        this.contentValue = content;
        this._setValueAllTargets();
        this._validate();
    }

    // テンプレートフォームを保存する
    _saveTemplate() {
        const ENDPOINT_URL = this.templateidValue
            ? `/manage/scouts/templates/${this.templateidValue}.json`
            : `/manage/scouts/templates.json`;
        const body = {
            scout_template: {
                title: this.titleValue,
                content: this.contentValue,
                template_title: this.templateTitleValue,
                sender: this.senderValue,
                template_type: this.scouttypeValue,
            },
        };

        this.brandidValue
            ? (body.scout_template.scout_template_brand_attributes = {
                  brand_id: this.brandidValue,
              })
            : undefined;
        this.storeidValue
            ? (body.scout_template.scout_template_store_attributes = {
                  store_id: this.storeidValue,
              })
            : undefined;

        const requestBody = {
            method: this.templateidValue ? "PATCH" : "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": getCsrfToken(),
            },
            body: JSON.stringify(body),
        };
        return fetch(ENDPOINT_URL, requestBody)
            .then(res => res.json())
            .then(json => {
                // console.log(this.templateidValue, json)
                const scoutTemplateModal = document.querySelector(
                    `div[data-controller="scout-template-modal-v3"]`
                );
                return json;
            });
    }

    // フォーム内のinputのvalueを設定する
    _setValueAllTargets() {
        document
            .querySelectorAll(
                `#${this.element.id} input[name="${this.scouttypeTarget.name}"]`
            )
            .forEach(scoutType => {
                if (this.scouttypeValue === scoutType.value) {
                    scoutType.checked = true;
                }
            });
        this.templateTitleTarget.value = this.templateTitleValue;
        if (this.scouttypeValue === "company") {
            this.brandidTarget.value = "";
            this.storebrandidTarget.value = "";
            this.storeidTarget.value = "";
        } else if (this.scouttypeValue === "brand") {
            this.brandidTarget.value = this.brandidValue;
            this.storebrandidTarget.value = "";
            this.storeidTarget.value = "";
            this._renderBrandStoreSelectOptions();
        } else if (this.scouttypeValue === "store") {
            this.brandidTarget.value = "";
            let storeId = this.storeidValue;
            let storebrandId = this.storebrandidValue;
            this._renderBrandStoreSelectOptions(storebrandId, storeId).finally(
                () => {
                    this.storeidTarget.value = storeId;
                    this.storebrandidTarget.value = storebrandId;
                    this._validate();
                }
            );
        }
        this.senderTarget.value = this.senderValue;
        this.titleTarget.value = this.titleValue;
        this.contentTarget.value = this.contentValue;
    }

    // フォームのバリデーション
    _validate() {
        if (this._isValid()) {
            this.submitTarget.disabled = false;
            this.submitTarget.classList.remove("is-disabled");
        } else {
            this.submitTarget.disabled = true;
            this.submitTarget.classList.add("is-disabled");
        }
    }

    // フォームのバリデーション
    _isValid() {
        const isValidForm = !!(
            this.templateTitleValue &&
            this.titleValue &&
            this.contentValue
        );
        const isValidCompany = this.scouttypeValue === "company";
        const isValidBrand = !!(
            this.scouttypeValue === "brand" &&
            this.brandidValue &&
            this.brandidTarget.value
        );
        const isValidStore = !!(
            this.scouttypeValue === "store" &&
            this.storeidValue &&
            this.storeidTarget.value
        );
        const isValidScoutType = isValidCompany || isValidBrand || isValidStore;

        return isValidScoutType && isValidForm;
    }

    // ブランドと店舗のセレクトボックスのoptionを描画する
    async _renderBrandStoreSelectOptions(brandId = null, defaultValue = null) {
        let html = `<option value="">先にブランドを選択してください</option>`;

        if (!brandId) {
            this.storeidTarget.innerHTML = html;
            this.storeidTarget.classList.add("is-disabled");
            this.storeidTarget.setAttribute("readonly", true);
            this.storeidTarget.setAttribute("disabled", true);
            return;
        }

        const stores = await fetchStores(brandId);
        stores.map(store => {
            html += `<option value="${store.id}" data-id="${store.id}" ${String(defaultValue) === String(store.id) ? 'selected="true"' : ""}>${store.name}</option>`;
        });
        this.storeidTarget.innerHTML = html;
        this.storeidTarget.classList.remove("is-disabled");
        this.storeidTarget.removeAttribute("readonly");
        this.storeidTarget.removeAttribute("disabled");
    }

    // scouttypeによって表示を切り替える
    _displayOnScouttype(selectValue) {
        const $brandElement = document.querySelector(
            `#${this.element.id} div[name="scout_template_unit_brand"]`
        );
        const $storeElement = document.querySelector(
            `#${this.element.id} div[name="scout_template_unit_store"]`
        );

        if (selectValue === "company") {
            this.brandidValue = "";
            this.storebrandidValue = "";
            this.storeidValue = "";
            $brandElement.classList.add("is-hide");
            $storeElement.classList.add("is-hide");
        } else if (selectValue === "brand") {
            this.storebrandidValue = "";
            this.storeidValue = "";
            $brandElement.classList.remove("is-hide");
            $storeElement.classList.add("is-hide");
        } else if (selectValue === "store") {
            $brandElement.classList.add("is-hide");
            $storeElement.classList.remove("is-hide");
        }
    }
}
